import { render, staticRenderFns } from "./VitaPage.vue?vue&type=template&id=38e85c33&scoped=true&"
import script from "./VitaPage.vue?vue&type=script&lang=js&"
export * from "./VitaPage.vue?vue&type=script&lang=js&"
import style0 from "./VitaPage.vue?vue&type=style&index=0&id=38e85c33&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38e85c33",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/yangliu/code/yyyliu.github.io/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('38e85c33')) {
      api.createRecord('38e85c33', component.options)
    } else {
      api.reload('38e85c33', component.options)
    }
    module.hot.accept("./VitaPage.vue?vue&type=template&id=38e85c33&scoped=true&", function () {
      api.rerender('38e85c33', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/VitaPage.vue"
export default component.exports
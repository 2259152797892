var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex justify-content-center bg-white" }, [
    _c("div", { staticClass: "cv" }, [
      _c("div", { staticClass: "text-center" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "cv-subtitle" }, [
          _vm._v("\n        " + _vm._s(_vm.meta.homepage) + " "),
          _c("span", { staticClass: "ml-2 mr-2" }, [_vm._v("·")]),
          _vm._v("\n        " + _vm._s(_vm.meta.email) + " "),
          _c("span", { staticClass: "ml-2 mr-2" }, [_vm._v("·")]),
          _vm._v("\n        " + _vm._s(_vm.meta.phone) + "\n      ")
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("section-header", { attrs: { title: "Education" } }),
          _vm._v(" "),
          _vm._l(_vm.education, function(edu) {
            return !edu.hide
              ? _c("div", { staticClass: "mb-3" }, [
                  _c("div", { staticClass: "d-flex justify-content-between" }, [
                    _c("div", { staticClass: "cv-heading" }, [
                      _vm._v(_vm._s(edu.name))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "cv-location" }, [
                      _vm._v(_vm._s(edu.location))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex justify-content-between" }, [
                    _c("div", [
                      _c("span", { staticClass: "cv-role" }, [
                        _vm._v(_vm._s(edu.degree))
                      ]),
                      _vm._v(" "),
                      edu.advisor
                        ? _c("span", [_vm._v(", " + _vm._s(edu.advisor))])
                        : _vm._e(),
                      _vm._v(" "),
                      edu.desc
                        ? _c("span", [_vm._v(", " + _vm._s(edu.desc))])
                        : _vm._e(),
                      _vm._v(" "),
                      edu.thesis
                        ? _c("div", [_vm._v("Thesis: " + _vm._s(edu.thesis))])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "cv-time" }, [
                      _vm._v(_vm._s(_vm.getTime(edu)))
                    ])
                  ])
                ])
              : _vm._e()
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("section-header", { attrs: { title: "Publications" } }),
          _vm._v(" "),
          _c("div", { staticClass: "cv-alt mb-2" }, [
            _vm._v("Refereed Publications")
          ]),
          _vm._v(" "),
          _vm._l(_vm.papers, function(paper) {
            return _c("div", { staticClass: "text-justify" }, [
              _c("div", {
                staticClass: "mb-3",
                domProps: { innerHTML: _vm._s(_vm.getPaper(paper)) }
              })
            ])
          }),
          _vm._v(" "),
          _vm.papers_under_review.length > 0
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "cv-alt mb-2" }, [
                    _vm._v("Manuscripts Under Review")
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.papers_under_review, function(paper) {
                    return _c("div", { staticClass: "text-justify" }, [
                      _c("div", {
                        staticClass: "mb-3",
                        domProps: { innerHTML: _vm._s(_vm.getPaper(paper)) }
                      })
                    ])
                  })
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "cv-alt mb-2" }, [_vm._v("Preprints")]),
          _vm._v(" "),
          _vm._l(_vm.papers_preprint, function(paper) {
            return _c("div", { staticClass: "text-justify" }, [
              _c("div", {
                staticClass: "mb-3",
                domProps: { innerHTML: _vm._s(_vm.getPaper(paper)) }
              })
            ])
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("section-header", { attrs: { title: "Professional Experience" } }),
          _vm._v(" "),
          _vm._l(_vm.work, function(w) {
            return _c("div", { staticClass: "text-justify" }, [
              _c("div", { staticClass: "mb-3" }, [
                _c("div", { staticClass: "d-flex justify-content-between" }, [
                  _c("div", { staticClass: "cv-heading" }, [
                    _vm._v(_vm._s(w.title))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "cv-location" }, [
                    _vm._v(_vm._s(w.location))
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-between mb-1" },
                  [
                    _c("div", { staticClass: "cv-role" }, [
                      _vm._v(_vm._s(w.role))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "cv-time" }, [
                      _vm._v(_vm._s(_vm.getTime(w)))
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "cv-list" },
                  _vm._l(w.desc, function(d) {
                    return _c("li", [_vm._v(_vm._s(d))])
                  }),
                  0
                )
              ])
            ])
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("section-header", { attrs: { title: "Teaching" } }),
          _vm._v(" "),
          _vm._l(_vm.teaching, function(sec) {
            return _c("div", { staticClass: "mb-3" }, [
              _c("div", { staticClass: "cv-heading" }, [
                _vm._v(_vm._s(sec.type))
              ]),
              _vm._v(" "),
              sec.desc ? _c("div", [_vm._v(_vm._s(sec.desc))]) : _vm._e(),
              _vm._v(" "),
              sec.items
                ? _c(
                    "ul",
                    { staticClass: "cv-list ml-3" },
                    _vm._l(sec.items, function(item) {
                      return _c("li", { staticClass: "mt-1" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(item.title) +
                            ", " +
                            _vm._s(item.time) +
                            ", " +
                            _vm._s(item.location) +
                            ".\n            "
                        ),
                        item.detail
                          ? _c("span", [_vm._v(_vm._s(item.detail) + ".")])
                          : _vm._e()
                      ])
                    }),
                    0
                  )
                : _vm._e()
            ])
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("section-header", { attrs: { title: "Service and Involvement" } }),
          _vm._v(" "),
          _vm._l(_vm.service, function(s) {
            return _c("div", { staticClass: "mb-2 d-flex" }, [
              _c("div", { staticClass: "cv-alt-time" }, [
                _vm._v(_vm._s(s.time))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "w-100" }, [
                _c("div", [
                  _c("b", [_vm._v(_vm._s(s.name))]),
                  s.location
                    ? _c("span", [_vm._v(", " + _vm._s(s.location))])
                    : _vm._e()
                ]),
                _vm._v(" "),
                s.desc
                  ? _c(
                      "div",
                      _vm._l(s.desc, function(d) {
                        return _c("div", [_vm._v(_vm._s(d))])
                      }),
                      0
                    )
                  : _vm._e()
              ])
            ])
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "cv-alt" },
        [
          _c("section-header", { attrs: { title: "Awards" } }),
          _vm._v(" "),
          _vm._l(_vm.awards, function(aw) {
            return _c("div", { staticClass: "mb-2 d-flex" }, [
              _c("div", { staticClass: "cv-alt-time" }, [
                _vm._v(_vm._s(aw.time))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "w-100" }, [
                _c("b", [_vm._v(_vm._s(aw.name))]),
                _vm._v(", " + _vm._s(aw.desc) + "\n        ")
              ])
            ])
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cv-title" }, [
      _c("span", { staticClass: "cv-firstname" }, [_vm._v("Yang")]),
      _vm._v(" "),
      _c("span", { staticClass: "cv-lastname" }, [_vm._v("Liu")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bg-theme", staticStyle: { "min-height": "100vh" } },
    [
      _c("div", { staticClass: "banner" }),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _vm.expired
          ? _c("div", { staticClass: "form" }, [_vm._m(0)])
          : _c("div", { staticClass: "form" }, [
              _c("div", { staticClass: "form-border" }),
              _vm._v(" "),
              _vm.submit
                ? _c("div", { staticClass: "form-content" }, [
                    _c("h3", { staticClass: "mb-4" }, [_vm._v("Thank you!")]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n          Thanks for signing up.\n          We will contact you soon if you are eligible.\n        "
                      )
                    ])
                  ])
                : _c("div", { staticClass: "form-content" }, [
                    _c("h3", { staticClass: "mb-4" }, [
                      _vm._v("Statistical Analysis Interview")
                    ]),
                    _vm._v(" "),
                    _vm._m(1),
                    _vm._v(" "),
                    _vm._m(2),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _vm._m(3),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c(
                          "b-form",
                          { on: { submit: _vm.onSubmit } },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "Email address:",
                                  "label-for": "emailInput"
                                }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    id: "emailInput",
                                    type: "email",
                                    required: "",
                                    placeholder: "Enter email"
                                  },
                                  model: {
                                    value: _vm.form.email,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "email", $$v)
                                    },
                                    expression: "form.email"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "Name:",
                                  "label-for": "nameInput"
                                }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    id: "nameInput",
                                    type: "text",
                                    required: "",
                                    placeholder: "Enter your name"
                                  },
                                  model: {
                                    value: _vm.form.name,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "name", $$v)
                                    },
                                    expression: "form.name"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label:
                                    "Link to a publication involving statistical analysis:",
                                  "label-for": "paperInput"
                                }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    id: "paperInput",
                                    type: "text",
                                    required: "",
                                    placeholder: "Enter a URL to your paper"
                                  },
                                  model: {
                                    value: _vm.form.paper,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "paper", $$v)
                                    },
                                    expression: "form.paper"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-theme mt-3",
                                attrs: {
                                  type: "submit",
                                  disabled: _vm.submitting
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.submitting
                                        ? "Submitting ... "
                                        : "Submit"
                                    ) +
                                    "\n            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _vm.err
                              ? _c(
                                  "span",
                                  { staticClass: "ml-3 text-danger" },
                                  [
                                    _vm._v(
                                      "\n              Unable to submit. Please try again later. If error persists, please contact us.\n            "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v(" "),
                        !_vm.showConsent
                          ? _c("div", { staticClass: "mt-2" }, [
                              _c(
                                "small",
                                { staticClass: "text-muted" },
                                [
                                  _vm._v(
                                    "\n              By proceeding, you agree to the terms.\n              "
                                  ),
                                  _c(
                                    "b-link",
                                    {
                                      on: {
                                        click: function($event) {
                                          _vm.showConsent = true
                                        }
                                      }
                                    },
                                    [_vm._v(" View")]
                                  )
                                ],
                                1
                              )
                            ])
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.showConsent
                      ? _c(
                          "div",
                          {
                            staticClass: "text-muted mt-3",
                            staticStyle: { "font-size": "0.8em" }
                          },
                          [
                            _vm._v(
                              "\n          By proceeding, you agree to the following terms.\n          You must be over 18 in age to participate.\n          Your participation is voluntary.\n          All of the information will be confidential, only accessible by approved research collaborators.\n          We will only use your contact information to contact you if your are eligible for the study.\n          None of the personal information will be included in the study result.\n          If you have any questions or concerns, please contact Yang Liu (yliu0@cs.washington.edu).\n          If you would like to talk to someone separate from the research team about a concern or\n          complaint about your rights as a possible research subject,\n          please contact the University of Washington Institutional Review Board at (206) 543-0098.\n          We cannot ensure the confidentiality of any information sent by email.\n        "
                            )
                          ]
                        )
                      : _vm._e()
                  ])
            ])
      ]),
      _vm._v(" "),
      _vm._m(4)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-content" }, [
      _c("h3", { staticClass: "mb-4" }, [_vm._v("Thank you ...")]),
      _vm._v(" "),
      _c("div", { staticClass: "mb-3" }, [
        _vm._v(
          "\n          Thanks for your interest! But we're no longer recruiting participants.\n        "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n          Have you conducted experiments and performed hypothesis testing?\n          Are you willing to share your data and analysis scripts with us?\n          If so, we would like to invite you to participate in a study about understanding the process of data analysis.\n\n          "
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "\n          In the study, we will ask you open-ended questions about the analysis decisions in one of your previous projects.\n          If you have concerns about us inspecting your analysis scripts,\n          please rest assured that our goal is not to find flaws and we will not publish anything without your consent.\n          Instead, we seek to understand analysis processes better.\n\n        "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "mb-2" }, [
        _c("b", { staticClass: "font-weight-bold" }, [_vm._v("Requirement:")]),
        _vm._v(" a publication involving statistical analysis")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mb-2" }, [
        _c("b", { staticClass: "font-weight-bold" }, [_vm._v("Duration:")]),
        _vm._v(" 60 to 90 minutes")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mb-2" }, [
        _c("b", { staticClass: "font-weight-bold" }, [_vm._v("Method:")]),
        _vm._v(" remote interview")
      ]),
      _vm._v(" "),
      _c("div", [
        _c("b", { staticClass: "font-weight-bold" }, [_vm._v("Reward:")]),
        _vm._v(" $20 Amazon gift card")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n          If you are interested, please provide your information below.\n          We will contact you soon to set up a time if you are eligible.\n          If you have any questions or concerns about the study,\n          feel free to email Yang Liu ("
      ),
      _c("a", { attrs: { href: "mailto:yliu0@cs.washington.edu" } }, [
        _vm._v("yliu0@cs.washington.edu")
      ]),
      _vm._v(").\n        ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer text-center" }, [
      _c("div", { staticClass: "text-muted" }, [
        _c("small", [_vm._v("Interactive Data Lab, University of Washington")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mt-3" }, [
        _c(
          "a",
          { attrs: { href: "http://idl.cs.washington.edu", target: "_blank" } },
          [
            _c("img", {
              staticClass: "img-logo",
              attrs: { src: "/build/images/idl-logo.png" }
            })
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "center-block content-width yyy-card mb-5",
      staticStyle: { padding: "30px" }
    },
    [
      _vm.project.image
        ? _c("a", { attrs: { href: _vm.project.url, target: "_blank" } }, [
            _c("img", {
              staticClass: "image-responsive",
              attrs: { src: _vm.project.image, alt: _vm.project.title }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.project.legend
        ? _c(
            "div",
            { staticClass: "text-xs text-justify text-muted pl-3 pr-3 mt-3" },
            [_vm._v(_vm._s(_vm.project.legend))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.project.embed
        ? _c(
            "div",
            { staticClass: "embed-responsive embed-responsive-narrow" },
            [
              _c("iframe", {
                staticStyle: { border: "none", width: "100%" },
                attrs: { src: _vm.project.embed }
              })
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("h3", { staticClass: "mt-4 mb-3" }, [
        _vm._v(_vm._s(_vm.project.title))
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mt-3 mb-3 text-bold" }, [
        _vm._v(_vm._s(_vm.project.authors.join(", ")))
      ]),
      _vm._v(" "),
      _vm._l(_vm.project.descriptions, function(d) {
        return _c("p", { staticClass: "text-justify" }, [
          _vm._v("\n    " + _vm._s(d) + "\n  ")
        ])
      }),
      _vm._v(" "),
      _c("div", { staticClass: "text-muted font-italic mt-2" }, [
        _vm._v(_vm._s(_vm.project.venue))
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-3" },
        [
          _vm._l(_vm.project.buttons, function(b) {
            return _c(
              "b-button",
              {
                key: b.name,
                staticClass: "mr-2",
                attrs: {
                  size: "sm",
                  squared: "",
                  variant: "outline-info",
                  href: b.link,
                  target: "_blank"
                }
              },
              [_vm._v(_vm._s(b.name))]
            )
          }),
          _vm._v(" "),
          _vm.project.ps
            ? _c("small", { staticClass: "text-muted text-xs" }, [
                _vm._v("\n      " + _vm._s(_vm.project.ps) + "\n    ")
              ])
            : _vm._e()
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
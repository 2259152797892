var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "center-block content-width text-left" }, [
      _c(
        "div",
        { staticClass: "pl-3 pr-3" },
        [
          _c("div", { staticClass: "d-md-flex mt-3 mt-md-5" }, [
            _vm._m(1),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "w-100 mt-3 mt-md-0 div-bio" },
              [
                _vm._l(_vm.bio, function(block) {
                  return _c(
                    "p",
                    { staticClass: "text-justify" },
                    _vm._l(block, function(part) {
                      return _c("span", [
                        !part.url
                          ? _c("span", [_vm._v(_vm._s(part.text))])
                          : _vm._e(),
                        _vm._v(" "),
                        part.url
                          ? _c(
                              "a",
                              {
                                staticClass: "link-primary",
                                attrs: { target: "_blank", href: part.url }
                              },
                              [_vm._v(_vm._s(part.text))]
                            )
                          : _vm._e()
                      ])
                    }),
                    0
                  )
                }),
                _vm._v(" "),
                _c(
                  "p",
                  { staticClass: "mt-1" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          squared: "",
                          size: "sm",
                          variant: "outline-secondary",
                          href: "mailto:yliu0@cs.washington.edu"
                        }
                      },
                      [
                        _c("i", { staticClass: "far fa-envelope mr-1" }),
                        _vm._v("\n                Email")
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        staticClass: "ml-2",
                        attrs: {
                          squared: "",
                          size: "sm",
                          variant: "outline-info",
                          href: "https://www.linkedin.com/in/yliu0/",
                          target: "_blank"
                        }
                      },
                      [
                        _c("i", { staticClass: "fab fa-linkedin mr-1" }),
                        _vm._v("\n                LinkedIn")
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        staticClass: "ml-2",
                        attrs: {
                          squared: "",
                          size: "sm",
                          variant: "outline-secondary",
                          href: "https://github.com/yyyliu",
                          target: "_blank"
                        }
                      },
                      [
                        _c("i", { staticClass: "fab fa-github mr-1" }),
                        _vm._v("\n                GitHub")
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        staticClass: "ml-2",
                        attrs: {
                          squared: "",
                          size: "sm",
                          variant: "outline-secondary",
                          href:
                            "https://scholar.google.com/citations?user=8Y6_oOgAAAAJ",
                          target: "_blank"
                        }
                      },
                      [
                        _c("i", { staticClass: "fab fa-google mr-1" }),
                        _vm._v(
                          "\n                Google Scholar\n              "
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              2
            )
          ]),
          _vm._v(" "),
          _c("h3", { staticClass: "mt-3 mb-3", attrs: { id: "pubs" } }, [
            _vm._v("Publications")
          ]),
          _vm._v(" "),
          _vm._l(_vm.publications, function(p, index) {
            return _c("div", { staticClass: "yyy-card row ml-0 mr-0" }, [
              _c(
                "div",
                { staticClass: "col-12 col-sm-4" },
                [
                  _c("router-link", { attrs: { to: "/project/" + index } }, [
                    _c("img", {
                      staticClass: "image-responsive",
                      attrs: { src: p.thumbnail, alt: p.title }
                    })
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-12 col-sm-8" },
                [
                  _c("router-link", { attrs: { to: "/project/" + index } }, [
                    _c("div", { staticClass: "text-bold text-dark" }, [
                      _vm._v(_vm._s(p.title))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-1" }, [
                    _vm._v(_vm._s(p.authors.join(", ")))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-1 font-italic text-muted" }, [
                    _vm._v(_vm._s(p.venue))
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mt-1" },
                    _vm._l(p.buttons, function(b) {
                      return _c(
                        "b-button",
                        {
                          key: b.name,
                          staticClass: "mr-2",
                          attrs: {
                            squared: "",
                            size: "sm",
                            variant: "outline-info",
                            href: b.link,
                            target: "_blank"
                          }
                        },
                        [_vm._v(_vm._s(b.name))]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ])
          }),
          _vm._v(" "),
          _c("h3", { staticClass: "mt-3 mb-3", attrs: { id: "projects" } }, [
            _vm._v("Projects")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row" },
            _vm._l(_vm.projects, function(p) {
              return _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c("div", { staticClass: "yyy-card pl-3" }, [
                  _c("div", [
                    _c("a", { attrs: { href: p.url, target: "_blank" } }, [
                      _c("img", {
                        staticClass: "image-responsive",
                        attrs: { src: p.thumbnail, alt: p.title }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-2 font-weight-bold" }, [
                    _c(
                      "a",
                      {
                        staticClass: "text-dark",
                        attrs: { href: p.url, target: "_blank" }
                      },
                      [_vm._v(_vm._s(p.title))]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-1" }, [
                    _vm._v(_vm._s(p.summary))
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mt-2" },
                    [
                      _vm._l(p.buttons, function(b) {
                        return _c(
                          "b-button",
                          {
                            key: b.name,
                            staticClass: "mr-2",
                            attrs: {
                              squared: "",
                              size: "sm",
                              variant: "outline-info",
                              href: b.link,
                              target: "_blank"
                            }
                          },
                          [_vm._v(_vm._s(b.name))]
                        )
                      }),
                      _vm._v(" "),
                      p.ps
                        ? _c("small", { staticClass: "text-muted" }, [
                            _vm._v(_vm._s(p.ps))
                          ])
                        : _vm._e()
                    ],
                    2
                  )
                ])
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c("div", { staticClass: "mt-3 mt-md-4" }),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("div", { staticClass: "m-3 text-center text-muted text-xs" }, [
            _vm._v(
              "\n          Copyright © 2016-" +
                _vm._s(_vm.current_year) +
                " Yang Liu\n        "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt-5" })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "simple-header bg-mint" }, [
      _c(
        "div",
        {
          staticClass:
            "center-block content-width d-flex justify-content-between"
        },
        [
          _c("div", { staticClass: "ml-3 mr-3 font-weight-bold" }, [
            _c("a", { staticClass: "no-link", attrs: { href: "/" } }, [
              _vm._v("Yang Liu")
            ])
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mr-3 text-center" }, [
      _c("img", {
        staticClass: "image-circle",
        staticStyle: { height: "200px" },
        attrs: { src: "/build/images/me.jpg", alt: "This is me" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }